.plan-member-div1 {
  padding-top: 110px;
  padding-bottom: 110px;
  position: relative;
}

.plan-member-text1 {
  color: #000;
  text-align: center;
  font-family: Helvetica;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 118.519% */
}

.plan-member-text2 {
  color: #171717;
  text-align: center;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 130.769% */
  margin-top: 12px;

}

.with-ads-btndiv1{
display: inline-flex;
padding:  4px 5px;
justify-content: center;
gap: 4px;
border-radius: 74px;
border: 1px solid #000;
background: #FFF8ED;
margin-top: 26px;
}

.with-ads-btn1{
display: flex;
padding:  16px 32px;
justify-content: center;
align-items: center;
gap: 19px;
border-radius: 52px;
background: #FFBD59;
color: #000;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 100% */
border: none;
transition: 0.3s ease-in-out;
}

.with-ads-btn2{
display: flex;
padding: 16px 32px;
justify-content: center;
align-items: center;
gap: 19px;
border-radius: 70px;
background: #FFF8ED;
color: #676767;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 100% */
border: none;
transition: 0.3s ease-in-out;

}

.plan-member-carddiv112 {
  margin-top: 60px;
}

.plan-member-carddiv1 {
  border-radius: 15px;
  /* border: 1.061px solid #d1d1d1;
  background: linear-gradient(
    180deg,
    rgba(237, 237, 237, 0.7) 0%,
    rgba(255, 255, 255, 0) 100%
  ); */
  /* box-shadow: 10px 14px 34px 0px rgba(191, 191, 191, 0.15); */
  /* backdrop-filter: blur(80px); */
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
background-image: url(../Image/Rectangle\ 41031.png);
background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 1.8px solid #D1D1D1

}

.plan-member-cardtext1 {
  color: #000;
  font-family: Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px; /* 106.667% */
  margin-bottom: 0px;
  position: relative;
}

.plan-member-cardtext1 span {
  color: #f90;
  font-family: Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 0px;
  position: relative;
}

.plan-member-cardtext1 span sup{
 color: #000;
font-family: Helvetica;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 14px; /* 400% */
position: absolute;
top: -6px;
margin-left: -46px;
width: max-content;
}

.plan-member-cardtext2 {
  color: #171717;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 212.5% */
  margin-bottom: 0px;
  margin-top: 2px;
}

.plan-member-cardbtn1 {
  color: #0d2a33;
  text-align: center;
  font-family: Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
  border-radius: 40px;
  border: 1px solid #000;
  background: linear-gradient(92deg, #ffd390 50.05%, #ffb649 99.05%);
  display: inline-flex;
  padding: 4px 30px;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 35px;
}

.plan-member-cardtext3 {
  color: #5c5c5c;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 212.5% */
}


.plan-member-cardbtndiv2323 {
  margin-top: 90px;
}

.plan-member-cardbtndiv2 {
  margin-top: 48px;
}

.plan-member-cardbtn2 {
    width: 126%;
    height: 68px;
    flex-shrink: 0;
    border-radius: 15.139px;
    background: linear-gradient(93deg, #0b232b 1.76%, #124354 68.29%);
    color: #fff;
    text-align: center;
    font-family: Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    /* bottom: 16px; */
    margin-left: -32px;
}
.plan-members-carddiv112 {
  border-radius: 10px;
  /* border: 1.062px solid #a0a0a0; */
  /* background: linear-gradient(180deg, rgba(71, 71, 71, 0.60) 100%, rgba(0, 0, 0, 0.00) 100%); */
  /* background-color: #0b232b; */
  /* box-shadow: 10px 10px 14px 0px rgba(0, 0, 0, 0.15); */
  backdrop-filter: blur(82px);
  padding-left: 50px;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
background-image: url(../Image/Rectangle\ 41033\ \(1\).png);
background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 1.8px solid #D1D1D1
}

.plan-member-cardtext112 {
  color: #fff;
  font-family: Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px; /* 106.667% */
  margin-bottom: 0px;
  position: relative;
}

.plan-member-cardtext112 span {
  color: #f90;
  font-family: Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  margin-bottom: 0px;
  position: relative;
}

.plan-member-cardtext112 span sup {
   color: #f90;
font-family: Helvetica;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 14px; /* 400% */
position: absolute;
top: -6px;
margin-left: -46px;
width: max-content;
transition: 0.3s ease-in-out;

}

.plan-member-cardtext212 {
  color: #b6b6b6;
  /* text-align: center; */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 28px; /* 212.5% */
}

.plan-member-cardtext312 {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 212.5% */
}

.plan-member-cardbtn212 {
  width: 126%;
  height: 68px;
  flex-shrink: 0;
  border-radius: 15.139px;
  background: linear-gradient(92deg, #f90 0%, #ffb547 99.54%);
  color: #fff;
  text-align: center;
  font-family: Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 320% */
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 16px; */
  margin-left: -32px;
  margin-top: 52px;
}

.money-text1{
  color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 21px; /* 242.857% */
margin-bottom: 0px !important;
margin-top: 13px;
text-align: center;
}

.money-text2{
  color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 21px; /* 242.857% */
margin-bottom: 0px !important;
margin-top: 15px;
text-align: center;
}









.add-web-div1 {
  /* margin-top: 35px; */
}

.add-web-div2 {
  border-radius: 15px;
  border: 1.061px solid #d1d1d1;
  background: linear-gradient(
    180deg,
    rgba(237, 237, 237, 0.7) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 10px 14px 34px 0px rgba(191, 191, 191, 0.15);
  backdrop-filter: blur(80px);
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

    .add-web-div-img212 img{
       margin-bottom: -25px;
       max-width: 128%;
    height: auto;
    margin-left: -36px;
    }

.add-web-text1 {
  color: #525252;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0px;
}

.add-web-text2 {
  color: #000;
  font-family: Helvetica;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 246.154% */
}

.add-web-text3 {
  color: #000;
  font-family: Helvetica;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 106.667% */
}

.add-web-text3 span {
  color: #f90;
  font-family: Helvetica;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
}

.add-web-div3 {
  border-radius: 15px;
  border: 1.061px solid #d1d1d1;
  background: linear-gradient(
    180deg,
    rgba(237, 237, 237, 0.7) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 10px 14px 34px 0px rgba(191, 191, 191, 0.15);
  backdrop-filter: blur(80px);
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  padding-left: 55px;
  padding-right: 55px;
}

.add-web-text4 {
  color: #525252;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.add-web-text5 {
  color: #171717;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 113.333% */
  text-decoration-line: underline;
}

.add-web-text5 span{
  color:#f90;
}





























/* ####################     Mobile Screen Css Media Queris ####################### */






@media screen and (max-width: 576px) {

.plan-member-div1 {
    padding-top: 70px;
    padding-bottom: 70px;
    position: relative;
}

.plan-member-text1 {
    font-size: 34px;
    line-height: 42px;
}

.plan-member-text2 {
    font-size: 18px;
    line-height: 30px;
    margin-top: 0px;
}

.with-ads-btn1, .with-ads-btn2 {
    font-size: 14px;
    gap: 19px;
    justify-content: center;
    line-height: 20px;
    padding: 14px 28px;
    transition: .3s ease-in-out;
}

.money-text1, .money-text2 {
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 0 !important;
}


.plan-member-carddiv112 {
    margin-top: 50px;
    row-gap: 30px;
}

.plan-member-carddiv1 {
    padding-top: 40px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 25px;
}


.plan-member-cardtext1 {
    font-size: 54px;
}

.plan-member-cardtext1 span{
    font-size: 54px;
}

.plan-member-cardbtn1 {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 25px;
}

.plan-member-cardbtndiv2 {
    margin-top: 60px;
}


.plan-member-cardbtndiv2323 {
  margin-top: 60px;
}

.plan-members-carddiv112 {
    padding-left: 40px;
    padding-top: 40px;
    padding-right: 35px;
    padding-bottom: 25px;
}

.plan-member-cardtext112 {
    font-size: 54px;
}

.plan-member-cardtext112 span{
    font-size: 54px;
}


.plan-member-cardbtn2 {
    width: 114%;
   
    margin-left: -23px;
}

.plan-member-cardbtn212{
    width: 114%;
    margin-left: -22px;
}


.add-web-div3 {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 0px;

}

.add-web-text4 {
    color: #525252;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0px;
}


.add-web-text5 {
    font-size: 17px;
    line-height: 28px;
}


.add-web-div-img212 {
   display: flex;
   justify-content: center;
}

.add-web-div-img212 img{
      max-width: 144%;
    height: auto;
    margin-left: -20px;
  margin-bottom: 2px;
}


.add-web-div2 {
    padding-top: 24px;
    padding-bottom: 14px;
}

.add-web-div1 {
    margin-top: 0px;
    row-gap: 18px;
}
}